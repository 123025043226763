.stamp {
  background-color: #FFE6E8;
  overflow: hidden;
}
.stamp .form-input {
  width: 50%;
}
.stamp .stamp-header {
  background-color: #ECCFD1;
  padding: .8rem;
}
.stamp .stamp-body {
  padding: .8rem;
}
.stamp .stamp-body-line {
  position: relative;
  font-size: small;
  padding: .4rem;
  margin-top: 1.6rem;
  height: 1.6rem;
  border-top: .05rem solid;
}
.stamp .stamp-body-line :first-child {
  position: absolute;
  left: 0;
}
.stamp .stamp-body-line :last-child {
  position: absolute;
  right: 0;
}