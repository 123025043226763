.package-offer .package-offer-summary {
  width: 14rem;
}

.package-offer .package-offer-ctx {
  width: calc(100% - 14rem);
  min-height: 20rem;
}

.package-offer .package-offer-documents,
.package-offer .package-offer-notes {
  padding: 0;
}

.package-offer .package-offer-notes {
  margin-left: .8rem;
  max-width: 12rem;
}

.package-offer .package-offer-ctx {
  padding-left: .8rem;
}

@media(max-width: 800px) {
  .package-offer .package-offer,
  .package-offer .package-offer-summary,
  .package-offer .package-offer-ctx {
    max-width: none;
    min-width: 100%;
    padding: 0;
  }
  .package-offer .package-offer-documents {
    margin-top: .8rem;
  }
}
@media(max-width: 1140px) {
  .package-offer .package-offer-documents,
  .package-offer .package-offer-notes {
    max-width: none;
    min-width: 100%;
  }
  .package-offer .package-offer-notes {
    margin-left: 0;
  }
}
