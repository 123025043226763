@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(1.6rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.dropdown .menu.slide-up{
  animation: slide-up .15s ease 1;
  display: none;
  position: absolute;
  left: 0;
  max-height: 50vh;
  overflow-y: auto;
  bottom: 1.8rem;
  top: auto;
}