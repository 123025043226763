/* Package Header  */
.package-header {
  position: sticky;
  top: 0;
  display: flex;
  z-index: 3;
}

.package-header.no-stick {
  position: relative;
}

.package-header--container {
  flex-grow: 1;
}

.package-header--image {
  position: relative;
  height: 8rem;
  width: 8rem;
  border-radius: .2rem;
  overflow: hidden;
  cursor: pointer;
  margin-right: .8rem;
  transition: width .2s ease, height .2s ease;
}

.package-header--image svg {
  position: absolute;
  top: calc(50% - 1rem);
  left: calc(50% - 1rem);
  height: 2rem !important;
  width: 2rem !important;
  color: #fff;
}

.package-header--address {
  display: flex;
  justify-content: space-between;
}

.package-header--actions {
  display: flex;
}

.package-header--actions:empty {
  display: none;
}

.package-header--flags  {
  text-align: right;
}

.package-header--thumbs {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: none;
}

.package-header--thumbs::-webkit-scrollbar {
  display: none;
}

.package-header--thumbs > * {
  display: flex;
  position: relative;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 6rem;
  min-width: 6rem;
  height: 6rem;
  transition: filter .5s;
  overflow: hidden;
  padding: 0;
  border-radius: .2rem;
}

.package-header--thumbs > *:hover {
  filter: brightness(0.85);
}

.package-header--thumbs svg {
  position: absolute;
  width: 2rem;
  height: 2rem;
  left: calc(50% - 1rem);
  top: calc(50% - 1rem);
  color: white;
  cursor: pointer;
}

/* Styles for when toolbar is stuck */
.package-header.stuck {
  margin-top: 6.2rem;
}

.package-header.stuck .package-header--image {
  width: 1.8rem;
  height: 1.8rem;
  margin-right: .2rem;
}

.package-header.stuck .package-header--image svg,
.package-header.stuck .package-header--address {
  display: none;
}

.package-header.stuck .package-header--actions {
  padding: 0
}

/* Responsive styles */
@media (max-width: 960px) {
  .package-header--photogallery .thumb.thumb-left {
    margin-top: .8rem;
  }

  .package-header--characteristics {
    margin-top: .8rem;
  }
}

@media (max-width: 600px) {
  .package-header {
    position: relative;
    flex-direction: column;
  }

  .package-header--image {
    width: 100%;
    margin-right: 0;
    margin-bottom: .8rem;
    height: 10rem;
  }

  .package-header--address {
    flex-direction: column;
  }

  .package-header--characteristics {
    height: auto;
  }

  .package-header--characteristics .column {
    display: inline-flex;
  }

  .package-header--actions .btn-group {
    margin-left: auto
  }

  .package-header--flags  {
    text-align: left;
  }
}
