.brokerage-user-filter-list-tools{
  display: flex;
  align-items: center;
}

@media (max-width: 600px) {
  .brokerage-user-filter-list-tools{
    float: unset !important;

  }

  #brokerage-user-filter-list-tools-role{
    display:block;
    margin-top: 0.4rem;
  }
  #brokerage-user-filter-list-tools-search{
    display:inline-block;
    margin-top: 0.4rem;
  }
}