.promotion-card .card-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
}

@media (max-width: 840px) {
  .promotion-card.card {
    flex-direction: column;
  }
  .promotion-card.card .card-image {
    width: 100%;
    padding: .8rem;
  }
  .promotion-card.card.card-document .card-image {
    border-right: none;
    border-bottom: .05rem solid #e7e9ed;
  }
  .promotion-card .card-footer .btn {
    width: 100%;
  }
}