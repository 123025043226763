.package-coversheet-editor .ql-toolbar.ql-snow {
  position: -webkit-sticky;
  position: sticky;
  z-index: 2;
  top: -1rem;
}

.panel-body.package-coversheet-editor-body{
  padding:0;
  overflow-y: hidden;
}