.package-link-layout {
  display: flex;
  justify-content: space-between;
}

.package-link-layout--information {
  width: calc(100% - 18rem - .8rem);
}

.package-link-layout--form {
  width: 18rem;
  height: fit-content;
}

.package-link-layout--image {
  display: block;
  width: 100%;
  height: 20rem;
  object-fit: cover;
}

.package-link-layout--image:hover {
  filter: brightness(.85);
}

@media(max-width: 840px){
  .package-link-layout {
    flex-direction: column-reverse;
  }
  .package-link-layout--information,
  .package-link-layout--form {
    width: 100%;
    height: auto;
  }
}
