
.package-offer-card {
  width: 12rem;
}
.package-offer-card + .package-offer-card {
  margin-left: .8rem;
}

@media(max-width: 600px) {
  .package-offer-card {
    min-width: 100%;
  }
}
