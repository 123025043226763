
.package-offer-actions-right {
    float: right;
}
@media(max-width: 600px) {
    .package-offer-actions-right {
        float: none;
        margin-top: 0.4rem;
    }
    .package-offer-preview-continue-offer{
        margin-top: 0.4rem;
    }
  }
