/* Blur-filter specific code */
/* top notch browsers */
.blur {
  -webkit-filter: blur(12px);
  filter: blur(12px);
}

/* IE */
.blur-ie {
  display: none;
  transform: scale(1.4);
}

/* IE10+ detection */
html[data-agent*="Trident"] .blur-wrapper .blur {
  display: none;
}
/* IE10+ detection */
html[data-agent*="Trident"] .blur-wrapper .blur-ie {
  display: block;
}