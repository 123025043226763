.package-detail--tabs.tab.tab-block {
  background-color: #f5f6f9;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.package-toolbar-header {
  /* prevent items from appearing in the background padding */
  padding-left: .8rem;
  margin-left: -.8rem;
  padding-right: .8rem;
  margin-right: -.8rem;
}

.has-tabs .package-toolbar-header {
  padding-top: .8rem;
  margin-top: -.8rem;
}

.package-detail--body {
  display: flex;
  flex-direction: column;
  padding: 0;
  position: relative;
}

.package-detail--tabs {
  display: flex;
  margin: 0;
  border-bottom: none;
  background-color: #f5f6f9;
}

.package-detail--content {
  flex-grow: 1;
}

.package-detail--tabs .tab-item a {
  display: inline-block;
  justify-content: center;
  flex-direction: column;
  border-top: .05rem solid #e7e9ed;
  border-bottom: .05rem solid #e7e9ed;
  border-left: .05rem solid transparent;
  border-right: .05rem solid transparent;
  width: 100%;
}

.package-detail--tabs .tab-item a.active {
  background-color: white;
  border-bottom-color: transparent;
}

.package-detail--tabs .tab-item:not(:first-child) a.active {
  border-left-color: #e7e9ed;
}

.package-detail--tabs .tab-item:not(:last-child) a.active {
  border-right-color: #e7e9ed;
}

.package-detail--tabs .tab-item a:focus {
  box-shadow: none;
}

.package-detail--tabs .tab-item a.active,
.package-detail--tabs .tab-item a:hover {
  color: #50596c !important;
}

.package-detail--tabs .tab-item .badge[data-badge]::after {
  position: static !important;
  margin-left: .2rem;
}

.package-detail--in-contract {
  display: flex;
  border-color: #e7e9ed;
  background-color: rgba(74,191,98,0.08);
}

@media (max-width: 600px) {
  .package-property-info .column {
    display: inline-flex;
  }

  .package-detail--in-contract {
    display: block;
  }

  .package-detail--in-contract .offer.btn {
    width: 100%;
    margin-left: 0;
  }
}
