.toast-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 500;
  pointer-events: none;
}
.toast-container .toast {
  width: 640px;
  max-width: calc(100% - .8rem);
  margin-left: auto;
  margin-right: auto;
  pointer-events: auto;
}