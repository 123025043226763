.grab-handle {
  content: '....';
  width: 1rem;
  height: 2rem;
  display: inline-block;
  overflow: hidden;
  line-height: .3rem;
  padding: .2rem 0.8rem;
  cursor: move;
  vertical-align: middle;
  font-size: .8rem;
  font-family: sans-serif;
  letter-spacing: .1rem;
  color:#acb3c2;
  font-weight: bold;
}

.grab-handle::after {
  content: '.. .. .. ..';
}