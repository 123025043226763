.package-document-upload--dropzone {
  border: 1px dashed;
  border-radius: 4px;
}

.package-document-upload--dropzone .divider {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 10rem;
}

.package-document-upload--dropzone .divider::after {
  background: inherit;
}