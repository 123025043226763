.modal.modal-gallery.active .modal-overlay,
.modal.modal-gallery:target .modal-overlay {
  background: rgba(0,29,33,0.8);
    bottom: 0;
    cursor: default;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0.9;
    min-width: 100vh;
    min-height: 100vh;
    padding-bottom: 0 !important;
}

/* remove unneccessary extra padding at the bottom inherited from modal class */
.modal.modal-lg.modal-gallery.active{
  padding-bottom: 0;
}

.modal.modal-gallery .modal-footer {
  background: rgba(0,29,33,0.8);
  opacity: 0.9;
}

.modal.modal-gallery .modal-header {
  border-bottom: none;
  overflow: auto;
}
.modal.modal-gallery .modal-header .btn-clear {
  color: white;
  font-size: 1.6rem;
  margin-right: .4rem;
}

.modal.modal-gallery .modal-container{
  margin-top: .8rem;
  box-shadow: none;
  background: #001B1F;
}

.modal.modal-gallery .modal-body {
  max-height: calc(100vh - 6rem);

  min-height: min-content;
  overflow-y: visible;

  position: relative;
  padding:0;
}

.carousel.photo-gallery {
  background: none !important;
}

.carousel .carousel-nav .nav-item.photo-gallery::before {
  content:none;
}

.carousel .carousel-nav.photo-gallery {
  position: relative !important;
  width: 100%;
}

.carousel .carousel-nav.photo-gallery::-webkit-scrollbar {
  display: none;
}

.carousel .carousel-container {
  max-height: calc(100vh - 16rem);
}

.carousel .carousel-container .item-next,
.carousel .carousel-container .item-prev {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 3rem;
}
.carousel .carousel-container .item-next .icon::before,
.carousel .carousel-container .item-prev .icon::before {
  border-width: .2rem;
}

.carousel .carousel-container .item-next {
  right: 0;
}

.carousel .carousel-container .item-prev {
  left: 0;
}