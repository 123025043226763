.package-document-card {
  width: 100%
}
.package-document-card .card-body {
  overflow: hidden;
}

.package-document-card .card-image {
  display: flex;
  position: relative;
  align-items: center;
  background: white;
  cursor: inherit;
}

.package-document-card .form-checkbox {
  padding: 0;
  position: absolute;
  top: 0;
  left: .2rem;
}

.package-document-card .card-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 1.5rem;
}

.package-document-card .card-body {
  position: relative;
  width: auto
}

.package-document-card .card-body .grab-handle {
  position:initial;
  top:initial;
  right:initial;
}

.package-document-card .card-body .card-actions-inline {
  position: absolute;
  top: .8rem;
  right: .4rem;
}

.package-document-card .card-body .btn-group {
  opacity: 0;
}

.package-document-card:hover {
  background-color: rgba(30,184,210,0.04);
}

.package-document-card:hover .card-body .btn-group {
  opacity: 1;
}