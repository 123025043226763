.package-card.card {
  overflow: visible;
}
.package-card.card .package-card-image {
  display: block;
  background-image: cover;
  background-size: cover;
  background-position: center center;
  width: 8rem;
}
.package-card.card .card-body {
  padding-bottom: .8rem;
}
.package-card.card .card-footer {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  max-width: 7.2rem;
}
.package-card.card .card-footer .form-label {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
}
@media (max-width: 840px) {
  .package-card.card {
    flex-direction: column;
  }
  .package-card.card .package-card-image {
      width: 100%;
      height: 12rem;
  }
  .package-card.card.card-document .card-image {
    border-right: none;
    border-bottom: .05rem solid #e7e9ed;
  }
}
