.text-editor {
  background: #fff;
}
.text-editor.disabled {
  background: #f5f6f9;
  pointer-events: none;
}

.ql-container {
  font-family: "Open Sans",-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",sans-serif;
  font-size: .8rem;
  color: #50596c;
}
.ql-toolbar.ql-snow {
  text-align: center;
  border: none;
  border-bottom: .05rem solid #e7e9ed;
  background:white;
  z-index: 1;
}
.ql-container.ql-snow {
  border: none;
}
