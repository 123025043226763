.document-page-image {
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
}

.document-page-image .overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0,0,0,0);
}

.overlay .show-on-hover {
  display: none;
}

.document-page-image:hover .overlay.hoverable {
  background-color: rgba(0,29,33,0.2);
}

.document-page-image:hover .show-on-hover {
  display: block;
}
