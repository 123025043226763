#package-contributors-pending .modal-container {
  max-width: 480px;
}
#package-contributors-pending .package-contributors + .package-contributors {
  margin-top: .8rem;
}
.package-contributor-card {
  position: relative;
  overflow: visible;
}
.package-contributor-card .chip {
  position: absolute;
  top: -.6rem;
  right: -.6rem;
}
.package-contributor-card .card-body {
  min-height: 5rem;
  min-width: 20rem;
}
.package-contributor-card + .package-contributor-card {
  margin-top: .8rem;
}
.package-contributor-card .tile .tile-content:not(:first-child) {
  padding-left: .8rem;
}
.package-contributor-card textarea {
  border: none;
}
@media (max-width: 600px) {
  .package-contributor-card .btn-group + .btn-group {
    margin-top: .4rem
  }
}